import {PaymentsMethodState} from '../state/payments-method-state';
import {AppState} from '../state/app-state';
import {PaymentsMethodTypes,AddPaymentsMethodAction} from '../actions/payments-method.actions';


const initialItemsState : PaymentsMethodState = {
    items: []
};

export function PaymentMethodReducer(state: PaymentsMethodState = initialItemsState, action:AddPaymentsMethodAction) : PaymentsMethodState {
	switch (action.type) {
		case PaymentsMethodTypes.ADD:
			state.items = {...action.payload};
		return {
			items: state.items
		}
	}
}