import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { EnvService } from '../utils/env.service';
import { constants } from '../../../config/app.constants';
import { Observable, timer, throwError, of } from 'rxjs';
import 'rxjs/add/operator/timeout';
import {
  first,
  timeout,
  catchError,
  take,
  single,
  distinctUntilChanged,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UnpaidBillsService {

  private paymentCms: any;
  private recaudos: any;

  constructor(private http: HttpClient,
    private env: EnvService) {
  }

  getContentPaymentCms(ubication: string) {
    const urlCms = constants.config.apiUrl + constants.config.adverstismentCmsSpaces + ubication;
    this.paymentCms = this.http.get(urlCms);
    return this.paymentCms;
  }

  getUnpaidBill(data: any, compania: any, email: any, validate_todo: any): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();
    const url = constants.config.apiUrl + constants.config.getUnpaidBill;
    body.append('formData', JSON.stringify(data));
    body.append('compania', compania);
    body.append('email', email);
    body.append('validate_todo', validate_todo);
    return this.http.post<any>(url, body, { headers })
  }


  getRecaudosGenerados(data: any): Promise<any> {

    //  return this.http.get<any>(this.env.apiGatewayFront + constants.config.getRecaudodGenerados + `=${data}`);

    const recaudo = constants.config.apiUrl + constants.config.getRecaudodGenerados + `=${data}`;
    this.recaudos = this.http.get(recaudo);
    return this.recaudos.toPromise();

  }

  createComment(reason: string, description: string, consecutive_document: string, rowid_document: string, rowid_factura: string, current_user: {}): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body = {
      reason,
      description,
      consecutive_document,
      rowid_document,
      rowid_factura,
      current_user
    };
    const url = constants.config.apiUrl + constants.config.createComment;
    return this.http.post<any>(url, body, { headers })
  }

}
