import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-public',
  templateUrl: './header-public.component.html',
  styleUrls: ['./header-public.component.scss']
})
export class HeaderPublicComponent implements OnInit {

  public logo: string;
  public contentProvider: any;

  constructor() { }

  ngOnInit() {
    this.logo = localStorage.getItem('logo');
    this.contentProvider = localStorage.getItem('provider');
  }

}
