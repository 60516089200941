import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { EnvService } from '../utils/env.service';
import { constants } from '../../../config/app.constants';
import { PaymentItem } from '../../classes/payment-item';
import { Observable, of, throwError } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  observe: 'response'
};

@Injectable({
  providedIn: 'root'
})

export class ConsolidatedInvoicesService {

  private paymentCms: any;
  private paymentMade: any;
  private companiaPrincipal: any;

  constructor(private http: HttpClient,
    private env: EnvService) { }


  getPaymentsMade(codPro: number, numeroDias: number, filtroFechas: number, fechaDesde: string, fechaHasta: string) {
    // tslint:disable-next-line: indent
    const urlCmsPayment = constants.config.apiUrl + constants.config.getPaymentHistory + `?
      codigoProveedor=${codPro}
      &numeroDias=${numeroDias}
      &filtroFechas=${filtroFechas}
      &fechaDesde=${fechaDesde}
      &fechaHasta=${fechaHasta}`;
    this.paymentMade = this.http.get(urlCmsPayment);
    return this.paymentMade;
  }


  // Obtener informacion en historico de pago.
  getPaymentItems(data: any, codigo: any, validador: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();
    body.append('formData', JSON.stringify(data));
    body.append('validador', validador);
    body.append('codigo', codigo);

    const url = constants.config.apiUrl + constants.config.getPaymentHistory;
    return this.http.post<any>(url, body, { headers })
      .pipe(
        tap(_ => console.log('fetched paymentItems')),
        catchError(this.handleError)
      );
  }

  //Obtener informacion en detalle de historico de pago.
  getRelatedPaymentsById(id: string, tipoDoc: any, docCliente: any, dias: any) {
    const json = { nro_docto: id, id_tipo_docto: tipoDoc, Id_Cliente: docCliente, dia: dias };
    const url = constants.config.apiUrl + constants.config.getRelatedPaymentsForHistoryDetail;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();
    body.append('formData', JSON.stringify(json));

    return this.http.post<any>(url, body, { headers })
      .pipe(
        tap(_ => console.log('fetched paymentRelatedItems')),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.log('an error ocurred', error.error.message);
    }
    else {
      console.log('Backend error', error.status);
      console.log('client side error', error.error);
    }
    return throwError(
      'Smething wrong' + error.error
    );
  }


  getUnpaidBill(data: any, compania: any, email:any, validate_todo:any): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();
    const url = constants.config.apiUrl + constants.config.getConsolidatedInvoices;
    body.append('formData', JSON.stringify(data));
    body.append('compania', compania);
    body.append('email', email);
    body.append('validate_todo', validate_todo);

    return this.http.post<any>(url, body, { headers })
  }

  getDasboardAgr(data: any, compania: any): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();
    const url = constants.config.apiUrl + constants.config.getDashboardAgr;
    body.append('formData', JSON.stringify(data));
    body.append('compania', compania);
    return this.http.post<any>(url, body, { headers })
  }

  guardarCompaniaBusqueda(compania) {
    console.log("compania", compania);
    localStorage.setItem('terceroCompania', JSON.stringify(compania));
    localStorage.setItem('companiaPrincipal', JSON.stringify(compania));
    return true;
  }

  getCompaniaPrincipal() {

    const url = constants.config.apiUrl + constants.config.getCompaniaPrincipal;
    this.companiaPrincipal = this.http.get(url);
    return this.companiaPrincipal;
  }
}

