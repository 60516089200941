
import { Injectable } from '@angular/core';
import { from, of } from 'rxjs';
import { map, toArray } from 'rxjs/operators';
import { NumericService } from '../../services/numerics/numeric.service';

@Injectable({
  providedIn: 'root'
})
export class ManageLocalStorageService {

  constructor(
    private numeric: NumericService
  ) {

  }
  //Objeto para indicar que parametros del backend se setearán en localStorage
  public objectsToFind: Array<string> = [
    'valor_minimo_abono_agr',
    'valor_minimo_anticipo_agr'
  ];

  setRowsSelected(newValue: any) {
    localStorage.setItem('rowsSelected', newValue);
  }

  limitNavigation(url: string) {
    if (url != '/unpaid-bills' && url != '/confirm-payment') {
      this.setRowsSelected(null);
    }
  }

  //Recibe la parametrizacion de AGP proveniente de backend y la setea a LocalStorage
  //Segun this.objectsToFind
  setContentAgr(agpContentResponse: any) {
    const transformToArray = from(this.objectsToFind)
      .pipe(
        map(x => {
          var foundItem = Object.keys(agpContentResponse).find((value: any) => {
            return value === x;
          });
          return foundItem;
        })
      )
      .subscribe(response => {
        if (response != undefined) {
          localStorage.setItem(response, agpContentResponse[response]);
          // console.log("pair", response, agpContentResponse[response]);
        }
      });
  }

  //Traer un item desde el local storage
  //FormatValue para parsear a formato valor
  getItemByName(nameInLocalStorage: string, formatValue: boolean = false): string {
    return formatValue ? this.numeric.camposNumerico(localStorage.getItem(nameInLocalStorage)) : localStorage.getItem(nameInLocalStorage);
  }
}
