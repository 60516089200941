import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { itemsActionReducer } from '../../store/reducers/payments-items.reducer';
import { PaymentMethodReducer } from '../../store/reducers/payments-method.reducer';
import { CurrentPaymentMethodReducer } from '../../store/reducers/current-payment-method.reducer';
import { UnlayerReducer } from '../../store/reducers/unlayer.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature('itemsState', itemsActionReducer),
		StoreModule.forFeature('paymentsMethod', PaymentMethodReducer),
		StoreModule.forFeature('currentPaymentsMethodItem', CurrentPaymentMethodReducer),
		StoreModule.forFeature('unlayerState', UnlayerReducer)
	],
})

export class PaymentsItemsStoreModule { }