import { Component, OnInit } from '@angular/core';
import {AdverstismentService} from '../shared/services/advertisments/adverstisment.service';

@Component({
  selector: 'app-frequent-quest',
  templateUrl: './frequent-quest.component.html',
  styleUrls: ['./frequent-quest.component.scss']
})
export class FrequentQuestComponent implements OnInit {

    page = 'PAG-57';
    cmsData: any;

    public contentB2s = {
        contact: 0,
        provider: 0
    } ;

    constructor( public adService: AdverstismentService) {

    }

    ngOnInit() {
			this.adService.getAdverstisments(this.page).subscribe((data: any) => {
					this.cmsData = data;
			});
			this.getContentB2s();
    }

    getContentB2s() {
			const provider = localStorage.getItem('provider');
			const contact = localStorage.getItem('contact');

			this.contentB2s.contact = parseFloat(contact);
			this.contentB2s.provider = parseFloat(provider);
    }
}
