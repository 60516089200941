import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class InactivityService {
    private timeout: any;

    constructor(private router: Router) { }

    initInactivityTimer(time) {
        window.addEventListener('click', () => this.resetTimer(time));
        this.startTimer(time);
    }

    startTimer(time) {
        time = time * 60 * 1000; // 5 microseconds by default
        this.timeout = setTimeout(() => {
            localStorage.clear();
            this.router.navigate(['/login']);
            this.resetTimer(time);
        }, time);
    }

    resetTimer(time) {
        clearTimeout(this.timeout);
        this.startTimer(time);
    }
}
