import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material';
import { UnpaidBillsService } from 'src/app/services/unpaid-bills/unpaid-bills.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as moment from 'moment';
import 'moment/locale/es';
import { catchError } from 'rxjs/operators';
import { from, Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-modal',
    templateUrl: './comments-modal.component.html',
    styleUrls: ['./comments-modal.component.scss'],
    animations: [
        trigger('modalAnimation', [
            state('void', style({ opacity: 0 })),
            state('*', style({ opacity: 1 })),
            transition(':enter', animate('300ms ease-in')),
            transition(':leave', animate('300ms ease-out')),
        ]),
    ],
})
export class CommentsModalComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<CommentsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _unpaidBillService: UnpaidBillsService,
        private _toastr: ToastrService,
    ) {
    }

    reason_list = {
        'question': 'Pregunta',
        'request': 'Solicitud',
        'incorrect_value': 'Valor errado',
        'nonconformity': 'No conformidad',
    };

    reason: string = '';
    description: string = '';
    minLength: number = 10;
    remainingCharacters: number = 500;
    loadingCreateComment: boolean = false;
    loadingComments: boolean = false;
    currentUser = JSON.parse(localStorage.getItem('userData'));
    comments = this.data.comments.map((comment: any) => ({
        ...comment,
        date: moment(comment.date).format('L'),
    }));

    columns = ['date', 'reason', 'description', 'answer', 'owner', 'status'];

    ngOnInit() {
        console.log(this.data)
    }

    onDescriptionInput(event: Event): void {
        const target = event.target as HTMLTextAreaElement;
        this.remainingCharacters = 500 - target.value.length;
    }

    onClose(): void {
        this.dialogRef.close();
    }

    getStatusStyles(status: string): string[] {
        if (status === 'Activo') {
            return ['active'];
        } else if (status === 'Cerrado') {
            return ['closed'];
        }
    }

    createComment(): void {
        this.loadingCreateComment = true;

        this._unpaidBillService
            .createComment(this.reason, this.description, this.data.documento, this.data.rowid_doc, this.data.id, this.currentUser.contactEmail)
            .pipe(
                catchError((error) => {
                    this.loadingCreateComment = false;
                    console.error('Error en la solicitud:', error);
                    this._toastr.error('Ocurrió un error al crear un comentario.', '¡Oops!');

                    return throwError(error);
                })
            )
            .subscribe((response: any) => {
                this.loadingCreateComment = false;
                this.comments = response.map((comment: any) => ({
                    ...comment,
                    date: moment(comment.date).format('L'),
                }));
                this.reason = '';
                this.description = '';
            });

    }
}