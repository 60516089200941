import { Action ,createAction, props } from '@ngrx/store';

export namespace UnlayerTypes {
    export const ADD = '[UnlayerMethod] add';
    export const REMOVE = '[UnlayerMethod] remove';
}

export class AddUnlayerAction implements Action {
    readonly type = UnlayerTypes.ADD;
    public payload: boolean;

    constructor(public templateOpen: any){
        this.payload = templateOpen;
    }
}

export type UnlayerAction = AddUnlayerAction;