import { Action ,createAction, props } from '@ngrx/store';

export namespace PaymentsMethodTypes {
    export const ADD = '[PaymetsMethod] add';
    export const REMOVE = '[PaymetsMethod] remove';
}

export class AddPaymentsMethodAction implements Action {
    readonly type = PaymentsMethodTypes.ADD;
    public payload: Object[];

    constructor(public items: any){
        this.payload = items;
    }
}

export type PaymentMethodAction = AddPaymentsMethodAction;