import { element } from 'protractor';
import { Component, OnInit, Input } from '@angular/core';
import { CompanieInfoService } from '../../services/companie-info/companie-info.service';
import {AdverstismentService} from '../../services/advertisments/adverstisment.service';
import {first} from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  contact: any;
  userLogged: boolean;
  footerInfo: any;
  private page = ['PAG-9'];
  private infoPage = {};
  // tslint:disable-next-line:no-input-rename
  @Input('b2sContent')
  public b2sContent: any;
  thisDiaErp: any;
  public showConsolidatedWallet = 0;
  public showConsolidatedHistory = 0;
  public showContact = false;
  jsonUser: any;


  // tslint:disable-next-line:variable-name
  constructor( private _companieInfo: CompanieInfoService,
               // tslint:disable-next-line:variable-name
               public _adService: AdverstismentService) {
    this.userLogged = JSON.parse(localStorage.getItem('authenticate'));
  }

  ngOnInit() {
    this.getFooterInfo();
    this.getInfoPage();
    this.thisDiaErp = JSON.parse(localStorage.getItem('thisDiaErp'));
    this.jsonUser = JSON.parse(localStorage.getItem('userData'));
  }

  getFooterInfo() {
    this._companieInfo.getService()
        .subscribe( (footerResponse: any) => {
          localStorage.setItem('companie', JSON.stringify(footerResponse));
          const footerResponseKeys = Object.keys(footerResponse);
          footerResponseKeys.forEach(element => {
            if (footerResponseKeys[element]) {              
              this.showContact = true;
            }
          })
          
          return this.footerInfo = footerResponse;
      });
  }

  getInfoPage() {
    this._adService.getInfoPage(this.page).pipe(first()).subscribe(
      (response: any) => {
        response.forEach((value) => {
          this.infoPage[value.codigo] = value;
        });
      }, (error: any) => {
        console.error(error.status);
        console.error(error.statusText);
      }
    );
  }
}
