import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../utils/env.service';
import { ToastrService } from 'ngx-toastr';
import { constants } from '../../../config/app.constants';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  private url: string;
  private contenidoIntro: any = {};
  private contenidoTips: any = {};

  constructor(private http: HttpClient,
    private env: EnvService,
    private _toastr: ToastrService) {
  }

  recoveryPassword(useremail: string, company: string) {
    const url = constants.config.apiUrl + constants.config.recoverPassword;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');

    const body: FormData = new FormData();
    body.append('data', 'AGR');
    body.append('email', useremail);
    body.append('company', company);

    return this.http.post(url, body, { headers });
  }

  contentIntro(ubucation: string) {
    const urlCms = constants.config.apiUrl + constants.config.adverstismentCmsSpaces + `${ubucation}`;
    this.contenidoIntro = this.http.get(urlCms);
    return this.contenidoIntro;
  }

  contentTips(ubucation: string) {
    const urlCms = constants.config.apiUrl + constants.config.adverstismentCmsSpaces + `${ubucation}`;
    this.contenidoTips = this.http.get(urlCms);
    return this.contenidoTips;
  }

  getErpFailureMessage() {
    this.contentIntro('ERROR_ERP')
      .subscribe((cmsResponseIntro: any) => {
        this._toastr.error(cmsResponseIntro.contenido, '¡Oops!', { enableHtml: true });
      });
  }

}
