import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../utils/env.service';
import { constants } from '../../../config/app.constants';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const EXPIRES_IN = 'expire_token';
const CLIENT_ID = 'client_id';
const CLIENT_SECRET = 'client_secret';

@Injectable({
  providedIn: 'root'
})

export class TokenService {
  private url: string;

  constructor(private http: HttpClient, private env: EnvService) {
    this.url = this.env.apiGatewayFront;
  }

  public getToken(): string {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  public getExpireInToken(): number {
    return parseInt(localStorage.getItem(EXPIRES_IN));
  }

  public getClientId(): string {
    return localStorage.getItem(CLIENT_ID);
  }

  public getClientSecret(): string {
    return localStorage.getItem(CLIENT_SECRET);
  }

  public saveToken(token): void {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  public saveRefreshToken(refreshToken): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }

  public saveClientId(id): void {
    localStorage.setItem(CLIENT_ID, id);
  }

  public saveClientSecret(secret): void {
    localStorage.setItem(CLIENT_SECRET, secret);
  }

  public removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  public removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }

  public saveExpiresToken(expiresIn: string) {
    let expireDate = Date.now();
    expireDate += parseInt(expiresIn);
    localStorage.setItem(EXPIRES_IN, new Date(expireDate).getTime().toString());
  }
}
