import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-advertisements',
  templateUrl: './advertisements.component.html',
  styleUrls: ['./advertisements.component.scss']
})
export class AdvertisementsComponent implements OnInit {
  @Input() public positions: number;
  @Input() public typeContent: string;
  @Input() public contentData: any;


  constructor() { }

  ngOnInit() {
  }

}
