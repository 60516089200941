import { Component, OnInit, Input } from '@angular/core';
import { CompanieInfoService } from '../../services/companie-info/companie-info.service';
import { AdverstismentService } from '../../services/advertisments/adverstisment.service';
import { first } from 'rxjs/operators';
import { HeaderService } from '../../../services/header/header.service';

@Component({
  selector: 'app-footer-logout',
  templateUrl: './footer-logout.component.html',
  styleUrls: ['./footer-logout.component.sass']
})
export class FooterLogoutComponent implements OnInit {

  contact: any;
  userLogged: boolean;
  footerInfo: any;
  private page = ['PAG-9'];
  private infoPage = {};
  public selectedCompany;
  // tslint:disable-next-line:no-input-rename
  @Input('b2sContent')
  public b2sContent: any;
  // tslint:disable-next-line:variable-name
  constructor(private _companieInfo: CompanieInfoService,
    // tslint:disable-next-line:variable-name
    public _adService: AdverstismentService,
    // tslint:disable-next-line:variable-name
    private _hedaerService: HeaderService) {
    this.userLogged = JSON.parse(localStorage.getItem('authenticate'));
  }

  ngOnInit() {
    this.selectedCompany = localStorage.getItem('selectedCompany');
    this.getFooterInfo();
    this.getInfoPage();
    this.getContentB2s();
  }


  getFooterInfo() {
    this._companieInfo.getService()
      .subscribe((footerResponse: any) => {
        localStorage.setItem('companie', JSON.stringify(footerResponse));
        return this.footerInfo = footerResponse;
      });
  }


  getInfoPage() {
    this._adService.getInfoPage(this.page).pipe(first()).subscribe(
      (response: any) => {
        response.forEach((value) => {
          this.infoPage[value.codigo] = value;
        });
      }, (error: any) => {
        console.error(error.status);
        console.error(error.statusText);
      }
    );
  }


  getContentB2s() {
    this._hedaerService.getAGRParams(this.selectedCompany)
      .subscribe((contentReponse: any) => {
        return this.b2sContent = contentReponse.data;
      });
  }
}

