import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../services/auth/auth.service';
import { LoginService } from '../services/login/login.service';
import { PrintService } from '../shared/services/print/print.service';
import { AdverstismentService } from '../shared/services/advertisments/adverstisment.service';
import { ConsolidatedInvoicesService } from '../services/consolidated-invoices/consolidated-invoices.service';
import { HeaderService } from '../services/header/header.service';
import { ActivatedRoute } from '@angular/router';
import { first, timeout } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  @ViewChild('sealRef') sealRef;


  public registerForm: FormGroup;
  public contentCms: any;
  public logo: string;
  public imagen_login: string;
  public data: any;
  public dataForm = {
    inputEmail: '',
    inputPassword: '',
    inputCompany: ''
  };
  public contentB2s = {
    contact: 0,
    provider: 0
  };

  public page = 'PAG-66';
  public cmsData: any;
  public companiaPrincipal: any;
  public seleccionarCompania = {
    company: '',
    year: ''
  };
  public thirdCompanies: any[] = [];


  constructor(private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private loginService: LoginService,
    private headerService: HeaderService,
    private printService: PrintService,
    private _route: ActivatedRoute,
    public adService: AdverstismentService,
    private consolidatedInvoicesService: ConsolidatedInvoicesService
  ) {

    if (this.authService.isAutenticate()) {
      this.router.navigate(['/dashboard']);
    }
    this.companiaPrincipal = JSON.parse(localStorage.getItem('companiaPrincipal'));
    this.thirdCompanies = JSON.parse(localStorage.getItem('companies'));
    this.headerService.getInitData().subscribe((initParams) => {
      this.thirdCompanies = initParams
      localStorage.setItem('companies', JSON.stringify(initParams));
    });

    this._route.params
      .subscribe(params => {

        const json = {
          data: 20,
        };
        this.getLoginService(json);

      });


  }

  ngOnInit() {
    this.dataForm.inputCompany = Object.keys(this.thirdCompanies)[0];
    // $('[data-toggle="tooltip"]').tooltip();
    // this.logo = localStorage.getItem('logo');
    // const interval = setInterval(() => {
    //   this.getContentB2s();
    //   clearInterval(interval);
    // }, 500);
  }

  authentication(form: NgForm) {
    const userName = form.value.inputEmail;
    const password = form.value.inputPassword;
    const company = Object.keys(this.thirdCompanies)[0];
    let message = '';

    this.registerForm = this.formBuilder.group({
      email: [userName, [Validators.required, Validators.pattern('^([a-zA-Z0-9_.+-])+\\@(([a-zA-Z0-9-])+\\.)+([a-zA-Z0-9]{2,4})+$')]],
      password: [password, [Validators.required]],
      company: [company, [Validators.required]]
    });

    if (this.registerForm.controls.email.errors) {
      if (this.registerForm.controls.email.errors.required) {
        message = message + '<br>Ingrese el correo electrónico';
      }
      if (this.registerForm.controls.email.errors.pattern) {
        message = message + '<br>Ingrese un correo válido.';
      }
    }
    if (this.registerForm.controls.password.errors) {
      if (this.registerForm.controls.password.errors.required) {
        message = message + '<br>Ingrese la contraseña.';
      }
    }
    if (this.registerForm.invalid) {
      this.toastr.error(message, 'Faltan algunos datos por diligenciar', { enableHtml: true });
      return;
    }

    this.authService.login({ username: userName, password: password, company: company }).subscribe(authResponse => {
      console.log(authResponse);

      if (authResponse.data && !authResponse.error) {
        this.getAllCmsToAgp();
        localStorage.setItem('userData', JSON.stringify(authResponse.data));
        localStorage.setItem('selectedCompany', company);
        localStorage.setItem('session_token', authResponse.data.session_token);
        localStorage.setItem('authenticate', '1')
        this.router.navigate(['/dashboard']);
      } else {
        this.showError('Credenciales Invalidas');
      }
    }, error => {
      if (error.status === 500 || error.status === 0) {
        this.router.navigate(['/500']);
      }
    });
  }

  getCmsContent() {
    // const location = 'INICIO_AGR';
    // this.loginService.contentCms(location)
    //   .subscribe((loginResponse: any) => {
    //     return this.contentCms = loginResponse;
    //   });
  }

  getContentB2s() {
    const provider = localStorage.getItem('provider');
    const contact = localStorage.getItem('contact');

    this.contentB2s.contact = parseFloat(contact);
    this.contentB2s.provider = parseFloat(provider);
  }

  showError(message) {
    this.toastr.error(message, '', { enableHtml: true });
  }


  getAllCmsToAgp() {
    this.printService.getAllCms().subscribe(
      response => {
        this.searchInCmsAndSetLocalStorage(response);
      },
      error => {
        console.log('an InvoiceCmsMensaje error', error);
      }
    );
  }

  private searchInCmsAndSetLocalStorage(resultado: any) {
    const objectsToFind = [
      'MENSAJE_DE_FACTURAS_NO_DISPONIBLES_AGP',
      'MENSAJE_PAGO_FACTURAS_ANTERIORES_AGP',
      'old_payments_firts'
    ];

    const obsArray = from(objectsToFind);
    const subscribe = obsArray
      .pipe(
        map((x: any) => {
          const foundItem = resultado.find((value: any) => {
            return value.ubicacion === x;
          });
          return foundItem;
        })
      ).subscribe((response: any) => {
        if (response !== undefined) {
          localStorage.setItem(response.ubicacion, response.contenido);
        }
      });

  }

  ngAfterViewInit() {
    // this.adService.getAdverstisments(this.page).subscribe((data: any) => {

    //   const iframe = document.createElement('iframe');
    //   this.cmsData = data.espacios['1'].html;

    //   // APPLY STYLES TO IFRAME AS SUITABLE
    //   iframe.width = '250';
    //   iframe.height = '100';
    //   iframe.frameBorder = '0';
    //   iframe.scrolling = 'no';
    //   iframe.src = 'about:blank';
    //   // ADD OTHER PROPERTIES OF IFRAME AS SUITABLE
    //   const content = `${this.cmsData}`;
    //   this.sealRef.nativeElement.appendChild(iframe);

    //   iframe.contentWindow.document.open('text/html', 'replace');
    //   iframe.contentWindow.document.write(content);
    //   iframe.contentWindow.document.close();

    // });
  }

  /**
   * possibleCompaniesNumber
   */
  public possibleCompaniesNumber(): number {
    return Object.keys(this.thirdCompanies).length;
  }

  getLoginService(params) {
    
    this.loginService.getDataLogin(params).pipe(first(), timeout(1000)).subscribe(
      (response: any) => {
        if (response.error) {
          this.logo = localStorage.getItem('logo');
          this.imagen_login = localStorage.getItem('imagen_login');
        } else {
          this.logo = response.logo;
          this.imagen_login = response.imagen_login;

        }
      }, (error: any) => {
        this.logo = localStorage.getItem('logo');
        this.imagen_login = localStorage.getItem('imagen_login');
      }
    );
  }



}

