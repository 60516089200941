import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../utils/env.service';
import { constants } from '../../../config/app.constants';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private contenidoCms: any = {};

  constructor(private http: HttpClient,
    private env: EnvService) {
  }

  contentCms(location: string) {
    const urlCms = constants.config.apiUrl + constants.config.adverstismentCmsSpaces + location;
    this.contenidoCms = this.http.get(urlCms);
    return this.contenidoCms;
  }

  getUserInfo(userId) {
    const url = constants.config.apiUrl + constants.config.getUserInfo;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();

    body.append('userId', userId);

    return this.http.post(url, body, { headers });
  }

  getDataLogin(data: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/form-data');
    const body: FormData = new FormData();
    const url = this.env.apiGatewayFront + constants.config.getContent_loginAGR;
    body.append('formData', JSON.stringify(data));
    return this.http.post<any>(url, body, { headers });
  }


}
