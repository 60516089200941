import { Component, OnInit } from '@angular/core';
import {AdverstismentService} from '../shared/services/advertisments/adverstisment.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

    page = 'PAG-58';
    cmsData: any;
    public contentB2s = {
        contact: 0,
        provider: 0
      } ;
    constructor( public adService: AdverstismentService) {

    }

    ngOnInit() {
      this.adService.getAdverstisments(this.page).subscribe((data: any) => {
        this.cmsData = data;
      });
      this.getContentB2s();
    }

    getContentB2s() {
      const provider = localStorage.getItem('provider');
      const contact = localStorage.getItem('contact');

      this.contentB2s.contact = parseFloat(contact);
      this.contentB2s.provider = parseFloat(provider);
    }
}
