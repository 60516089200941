import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/services/header/header.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-internal-error',
  templateUrl: './internal-error.component.html',
  styleUrls: ['./internal-error.component.scss']
})
export class InternalErrorComponent implements OnInit {

  public selectedCompany;

  constructor(private headerService: HeaderService,
    private _router: Router) {
    this.statusServer();
  }

  ngOnInit() {
    this.selectedCompany = localStorage.getItem('selectedCompany');
  }

  statusServer() {
    this.headerService.getAGRParams(this.selectedCompany)
      .subscribe((authResponse: any) => {
      }, error => {
        if (error.status === 500 || error.status === 0) {
          this._router.navigate(['/500']);
        } else {
          this._router.navigate(['/login']);
        }
      });
  }
}
