import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import 'rxjs/add/operator/do';

@Injectable()
export class CookieInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('session_token');
    const thirdBranch = localStorage.getItem('selectedSucursal');
    let headers = request.headers;
    if (token && token !== '' && token !== 'undefined') {
      headers = headers.set('session-token', token);
    }
    if (thirdBranch && thirdBranch !== '' && thirdBranch !== 'undefined' && JSON.parse(thirdBranch) !== null) {
      headers = headers.set('selected-account', JSON.parse(thirdBranch).thirdId);
    }
    request = request.clone({
      headers: headers
    });
    return next
      .handle(request);
  }
}
