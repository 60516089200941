import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdverstismentService } from '../../shared/services/advertisments/adverstisment.service';
import { HeaderService } from '../../services/header/header.service';


@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotFoundComponent implements OnInit {

  page = 'PAG-8';
  cmsData: any;
  userLogged: boolean;
  b2sContent: any;
  public selectedCompany;

  constructor(public adService: AdverstismentService, private _headerService: HeaderService) {
    this.userLogged = JSON.parse(localStorage.getItem('authenticate'));
  }

  ngOnInit() {
    this.selectedCompany = localStorage.getItem('selectedCompany');
    this.adService.getAdverstisments(this.page).subscribe((data: any) => {
      this.cmsData = data;
      this.getContentB2s();
    });
  }

  getContentB2s() {
    this._headerService.getAGRParams(this.selectedCompany)
      .subscribe((contentReponse: any) => {
        return this.b2sContent = contentReponse;
      });
  }

}
