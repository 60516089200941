import {CurrentPaymentsMethodState} from '../state/current-payments-method-state';
import {AppState} from '../state/app-state';
import {CurrentPaymentsMethodTypes,CurrentPaymentMethodAction} from '../actions/current-payment-item.actions';


const initialItemsState : CurrentPaymentsMethodState = {
    currentPaymentsMethodItem: 0
};

export function CurrentPaymentMethodReducer(state: CurrentPaymentsMethodState = initialItemsState, action:CurrentPaymentMethodAction) : CurrentPaymentsMethodState {
	switch (action.type) {
		case CurrentPaymentsMethodTypes.ADD:
			state.currentPaymentsMethodItem = action.payload;
		return {
			currentPaymentsMethodItem: state.currentPaymentsMethodItem
		}
	}
}