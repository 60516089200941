import { Component, OnInit, Input } from '@angular/core';

export interface Indicator {
  label: string;
  value: string;
  icon: string;
  type: string;
  labelClassColor: string;
  BorderClassColor: string;
}

@Component({
  selector: 'app-header-indicator',
  templateUrl: './header-indicator.component.html',
  styleUrls: ['./header-indicator.component.scss']
})
export class HeaderIndicatorComponent implements OnInit {

  @Input() label = '';
  @Input() value = '';
  @Input() icon = '';
  @Input() type = '';
  @Input() labelClassColor = 'gray';
  @Input() BorderClassColor = 'blue';

  constructor() { }

  ngOnInit() {

  }

  translateColor(element, color) {
    let colorClass = '';
    switch (color) {
      case 'blue':
        colorClass = 'primary';
        break;
      case 'gray':
        colorClass = 'secondary';
        break;
      case 'green':
        colorClass = 'success';
        break;
      case 'red':
        colorClass = 'danger';
        break;
      case 'yellow':
        colorClass = 'warning';
        break;
      case 'black':
        colorClass = 'dark';
        break;
      case 'cyan':
        colorClass = 'info';
        break;
      default:
        colorClass = 'primary';
        break;
    }
    return element + '-' + colorClass;
  }











}
