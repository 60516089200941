import { AddUnlayerAction, UnlayerTypes } from "../actions/unlayer.actions";
import { UnlayerState } from "../state/unlayer-state";

const initialState: UnlayerState = {
    templateOpen: false
};

export function UnlayerReducer(state: UnlayerState = initialState, action: AddUnlayerAction): UnlayerState {
    switch (action.type) {
        case UnlayerTypes.ADD:
            state.templateOpen = action.payload;
            return {
                templateOpen: state.templateOpen
            }
    }
}